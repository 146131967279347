import React, { useEffect, useState } from 'react'
import TagListItem from '../../components/TagListItem'
import BlogListItem from '../../components/BlogListItem'
import SiteLayout from '../../components/layout/SiteLayout';
import { graphql, Link } from 'gatsby';
import { hostname } from '../../utils/constants';

export default function BlogIndex(props) {
  const [posts, setPosts] = useState(props.data.posts.posts.filter(post => post.frontmatter.language === props.pageContext.language));
  const [tags, setTags]= useState([]);
  const [texts, setTexts] = useState(props?.data?.posts?.posts?.filter(post => post.frontmatter.type === 'blog-index' && post.frontmatter.language === props.pageContext.language)[0]);
  const SHOW_ALL = "Mostrar todo";
  const [selectedTag, setSelectedTag] = useState(SHOW_ALL);

  let navigationLdJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": hostname + "/",
          "name": "Inicio"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": hostname + "/blog/",
          "name": "Blog"
        }
      }
    ]
  };

  useEffect(() => {
    if ((!tags || !tags.length) && posts && posts.length) {
      getTags();
    }
  }, [posts]);

  const getTags = () => {
    let blogTags = [];
    const posts = props.data?.posts?.posts.filter(post => post.frontmatter.language === props.pageContext.language);
    for (let post of posts) {
      if (post.frontmatter.type !== 'blog-index' && post.frontmatter.categories) {
        for (let category of post.frontmatter?.categories) {
          if (!blogTags.includes(category)) {
            blogTags.push(category);
          }
        }
      }
    }

    setTags(blogTags);
  }

  const filterByTag = (tag) => {
    setSelectedTag(tag === selectedTag || tag === SHOW_ALL ? SHOW_ALL : tag);
    if (tag !== SHOW_ALL) {
      setPosts(props.data.posts.posts.filter(post => post.frontmatter.type !== 'blog-index' && post.frontmatter.categories.includes(tag) && post.frontmatter.language === props.pageContext.language));
    } else {
      setPosts(props.data.posts.posts.filter(post => post.frontmatter.language === props.pageContext.language));
    }
  }


  return (
    <SiteLayout title={texts?.frontmatter?.title} relatedPages={texts?.fields?.relatedPages} lang={texts?.fields?.lang} canonical={texts?.fields?.canonical} seo={{metaTitle: texts?.frontmatter?.metaTitle, metaDescription: texts?.frontmatter?.metaDescription, metaImage: texts?.frontmatter?.metaImage?.publicURL, metaCover: texts?.frontmatter?.metaCover?.publicURL, twitterCard: texts?.frontmatter?.twitterCard, ldJson: texts?.frontmatter?.ldJson, navigationLdJson: navigationLdJson, keywords: texts?.frontmatter?.metaKeywords, slug: texts?.fields?.slug.replace("/posts/index", "")}}>
      <div className={"flex w-full flex-col items-center features-bg-image px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96"}>
        <div className={"w-full"}>
          <p className={"text-right w-full text-sm text-gray-500 mt-5"}><Link to={"/"}>Inicio</Link> > Blog</p>
        </div>
        <h1 className={"text-xl md:text-2xl lg:text-4xl text-center text-primary-default font-light pt-5 md:pt-10 md:pt-20"}>{texts?.frontmatter?.title}</h1>
      </div>
      <div className={"flex flex-col px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96 pt-10 text-sm text-gray-500 features-subtext"}>
        {texts?.frontmatter?.excerpt && <p>{texts.frontmatter.excerpt}</p>}
      </div>
      <div className={"flex w-full flex-col items-center px-5 md:px-10 lg:px-20 xl:px-60 xxl:px-96  mb-20"}>
        <div className={"flex flex-row items-center self-start mt-5 xl:mt-10 ignore-anchor"}>
          <div className={"flex flex-row flex-wrap"}>
            {tags && tags.length ? <TagListItem onClick={filterByTag} tag={SHOW_ALL} selectedTag={selectedTag} /> : null}
            {tags && tags.length ? tags.map((tag, index) => (
              <TagListItem onClick={filterByTag} tag={tag} selectedTag={selectedTag} key={index} />
            )) : null}
          </div>
        </div>
        {posts.map((post, index) => {
          return post.frontmatter.type !== 'blog-index' && <BlogListItem key={index} id={post.id} post={post.frontmatter} body={post.body} excerpt={post.excerpt} listExcerpt={post.frontmatter?.listExcerpt} reverse={index % 2}
                        slug={post.fields?.slug} />;
        })}
      </div>
    </SiteLayout>
  );
};

export const query = graphql`query BlogList {
    posts: allMdx(filter: {frontmatter: {type: {in: ["blog", "blog-index"]}}}) {
      posts: nodes {
        id
        body
        fields {
            slug
            canonical
            lang
            relatedPages {
              en {
                url
                country
              }
              it {
                url
                country
              }
              ro {
                url
                country
              }
              fr {
                url
                country
              }
              es {
                url
                country
              }
              de {
                url
                country
              }
              pl {
                url
                country
              }
              cz {
                url
                country
              }
            }
        }
        frontmatter {
          language
          metaTitle
          metaDescription
          metaImage {
            publicURL
          }
          metaCover {
            publicURL
          }
          metaKeywords
          twitterCard
          ldJson
          date(fromNow: true)
          title
          author
          categories
          excerpt
          listExcerpt
          type
          coverAlt
          cover {
            childImageSharp {
              gatsbyImageData(
                quality: 95
                tracedSVGOptions: {color: "#00ab6b"}
                placeholder: BLURRED
                transformOptions: {cropFocus: CENTER}
                layout: FULL_WIDTH
              )
            }
          }
        }
        excerpt
        id
      }
    }
  }`;
